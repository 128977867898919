(function ($) {
	"use strict";

	var editor = $('.image-editor');
	if (editor.length == 0) {
		return;
	}
	var url = editor.data('url');
	var preview = editor.find('.cropit-preview');
	if (editor.data('image')) {
		var imageSrc = editor.data('image');
		editor.cropit({imageState: {src: imageSrc }});
	} else {
		editor.cropit();
	}
	editor.cropit('previewSize', { width: 512, height: 512 });
	editor.cropit('onImageError', function(error) {
		if (error.code == 1) {
			alert('Image is too small');
		} else {
			alert('Image upload error');
		}
	});
	preview.height(preview[0].offsetWidth);
	editor.find('.cropit-image-input').hide();
	editor.find('.select-image-btn').click(function() {
		editor.find('.cropit-image-input').click();
	});

	editor.find('.rotate-cw-btn').click(function() {
		editor.cropit('rotateCW');
	});
	editor.find('.rotate-ccw-btn').click(function() {
		editor.cropit('rotateCCW');
	});

	editor.find('.save').click(function() {
		var image = editor.cropit('export', {
			type: 'image/png',
			originalSize: true
		});

		var btn = $(this);
		btn.attr('disabled', true);
		var fa = btn.find('.fa');
		var origIcon = fa[0].className;
		fa[0].className = 'fa fa-refresh fa-spin fa-fw';

		$.ajax(url, {
			'method': 'POST',
			'data': {
				'image': image
			},
			'success': function () {
				fa[0].className = 'fa fa-check';
				setTimeout(function () {
					fa[0].className = origIcon;
					btn.attr('disabled', false);
				}, 2000);
			}
		});
	})
})(jQuery);