function loadMaps() {
	"use strict";
	var language = jQuery('html').attr('lang');
	jQuery.ajax({
		url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9yjyR7JtbCKfN4-xSbwxrbCxfm6GKJc4&callback=initMaps&language=' + language,
		dataType: 'script',
		async: true
	});
}

function initMaps() {
	"use strict";
	jQuery('.map').each(function (index, element) {
		var directionsService = new google.maps.DirectionsService;
		var map = new google.maps.Map(element, {
			center: {lat: 47.5338768, lng: 19.0343904},
			zoom: 15
		});

		var zoom = 15;
		var travelMode = google.maps.TravelMode.TRANSIT;
		var origins = [];
		switch (jQuery(element).data('type')) {
			case 'air':
				travelMode = google.maps.TravelMode.TRANSIT;
				origins = [{'placeId': 'ChIJC4Y5_qTBQUcRoZpDUKb2pEw'}]; //Liszt Ferenc Intl.
				zoom = 10;
				break;
			case 'train':
				travelMode = google.maps.TravelMode.TRANSIT;
				origins = [
					{'placeId': 'ChIJExjd6IncQUcR19SYIurgM4c'}, //Keleti pu.
					{'placeId': 'ChIJFaKZBg3cQUcRgLGXyZxXeyI'} //Nyugati pu.
				];
				zoom = 12;
				break;
			case 'coach':
				travelMode = google.maps.TravelMode.TRANSIT;
				origins = [
					{'placeId': 'ChIJMdM2s-7bQUcR56M4uleoJiI'}, //Arpad hid autobuszallomas
					{'placeId': 'ChIJ3y0j1MHdQUcRDxHWygBS-P8'}, //Etele ter autobuszallomas
					{'placeId': 'ChIJ5T0Xg97cQUcRGeP7pI9T0nU'}, //Nepliget
					{'placeId': 'ChIJWaLJyJncQUcRpZNVelcgMeY'}, //Stadionok
					{'placeId': 'ChIJzc6wrKDeQUcRGbeN-DRRWOk'} //Szena ter
				];
				zoom = 11;
		}

		var onResize = function() {
			var center = map.getCenter();
			google.maps.event.trigger(map, "resize");
			map.setCenter(center);
			map.setZoom(zoom);
		};
		map.setZoom(zoom);
		var tabId = jQuery(element).parents('.tab-pane').attr('id');
		jQuery('a[href="#' + tabId + '"]').on('shown.bs.tab', onResize);

		google.maps.event.addDomListener(window, "resize", onResize);

		jQuery(origins).each(function(index, origin) {
			directionsService.route({
				origin: origin,
				destination: {'placeId': 'ChIJRcgGXlrZQUcRGSjj8aUWUTM'}, //Obudai egyetem
				travelMode: travelMode,
				transitOptions: {
					//arrivalTime: new Date(2016, 11, 26, 9, 0, 0, 0),
					routingPreference: 'FEWER_TRANSFERS'
				}
			}, function (response, status) {
				if (status == 'OK') {
					var directionsDisplay = new google.maps.DirectionsRenderer;
					directionsDisplay.setMap(map);
					directionsDisplay.setDirections(response);
				}
			});
		});
	});
}

if (jQuery('.map').length > 0) {
	loadMaps();
}